.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .list h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .list p {
  color: #666;
}

.Home .sampleAds {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Home .sampleAds .sampleAd {
  margin: 3px;
}

.centered {
  text-align: center;
}

.bolt {
  height: 3rem;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.Ads form {
  padding-bottom: 15px;
}

.Ads form textarea {
  height: 300px;
  font-size: 24px;
}

.Ad {
  background-color: black;
  color: white;
  width: 358px;
  height: 100px;
  font-family: Arial, Verdana, sans-serif;
  font-size: 9px;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  position: relative;
}

.Ad a {
  color: white;
  text-decoration: none;
}

.Ad a:hover {
  text-decoration: underline;
}

.Ad #advert {
  font-size: 18px;
  margin: 10px;
  vertical-align: middle;
  text-align: center;
  height: 60px;
  position: relative;
}

.Ad #advert > a, .Ad #advert > div {
  vertical-align: middle;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: none;
  overflow-y: scroll;
  max-height: 60px;

}

.Ad #advertise {
  text-align: right;
}

.Ad #next {
  position: absolute;
  right: 3px;
}
.Ad #prev {
  position: absolute;
  left: 3px;
}


@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

@media all and (min-width: 480px) {
  .Settings {
    padding: 60px 0;
  }

  .Settings form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.NewAd form {
  padding-bottom: 15px;
}

.NewAd form textarea {
  height: 300px;
  font-size: 24px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

