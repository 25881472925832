.Ads form {
  padding-bottom: 15px;
}

.Ads form textarea {
  height: 300px;
  font-size: 24px;
}

.Ad {
  background-color: black;
  color: white;
  width: 358px;
  height: 100px;
  font-family: Arial, Verdana, sans-serif;
  font-size: 9px;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  position: relative;
}

.Ad a {
  color: white;
  text-decoration: none;
}

.Ad a:hover {
  text-decoration: underline;
}

.Ad #advert {
  font-size: 18px;
  margin: 10px;
  vertical-align: middle;
  text-align: center;
  height: 60px;
  position: relative;
}

.Ad #advert > a, .Ad #advert > div {
  vertical-align: middle;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  overflow-y: scroll;
  max-height: 60px;

}

.Ad #advertise {
  text-align: right;
}

.Ad #next {
  position: absolute;
  right: 3px;
}
.Ad #prev {
  position: absolute;
  left: 3px;
}

